@tailwind base;
@tailwind components;
@tailwind utilities;

input,
button {
    outline: none;
}

html {
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    width: 7px;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #2c2c2c;
}

body::-webkit-scrollbar-thumb {
    background-color: rgb(235, 235, 235);
    border-radius: 10px;
}

body::-webkit-scrollbar-corner {
    background-color: rgb(31, 31, 31);
}

.swiper-list {
    margin-left: unset !important;
    margin-right: unset !important;
}

.shadow-strip {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.shadow-bg {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.scrollbar-light::-webkit-scrollbar {
    width: 5px;
    /* display: none; */
}

.scrollbar-light {
    overflow-y: scroll;
    mask-image: linear-gradient(to top, transparent, black),
        linear-gradient(to left, transparent 17px, black 17px);
    mask-size: 100% 20000px;
    mask-position: left bottom;
    -webkit-mask-image: linear-gradient(to top, transparent, black),
        linear-gradient(to left, transparent 17px, black 17px);
    -webkit-mask-size: 100% 20000px;
    -webkit-mask-position: left bottom;
    transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}

.scrollbar-light:hover {
    -webkit-mask-position: left top;
}

.scrollbar-light::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

}

.scrollbar-light::-webkit-scrollbar-thumb {
    background-color: rgb(73, 73, 73);
    outline: 1px solid rgb(42, 42, 42);

}

.text-gradient {
    background: -webkit-linear-gradient(#2937f0, #9f1ae2);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
}

.marque-text-area ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 70px;
    animation: CSSright 85s linear infinite running alternate;


}

.marque-text-area ul li a {
    -webkit-text-stroke: 2px #FFD700;
    -webkit-text-fill-color: transparent;
    font-size: 60px;
    font-weight: 700;
    white-space: nowrap;
    text-decoration: none;
    animation: textShine 8s ease-in-out infinite alternate;
}

@keyframes CSSright {
    0% {
        transform: translate(0, 0);

    }

    100% {
        transform: translate(-120%, 0);
    }
}

@keyframes textShine {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}

#categories .categories-card {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    color: #fff;
    border: 1px solid #2e2e2e;

}

#categories .categories-card:hover img {
    transform: scale(1.1);
}

#categories .categories-card img {
    width: 100%;
    max-height: 250px;
    height: 260px;
    object-fit: cover;
    display: block;
    transition: transform 0.5s;
}


#categories .categories-card h3 {
    text-align: center;
    font-size: 1.1rem;
    padding: 18px;
    margin-bottom: 0px;
    z-index: 99;
    background: #01030f;
    position: relative;
    height: 100%;
}